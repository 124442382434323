import React from 'react';

const Ceritficates = () => {
    return (
        <section id="projects" className="projects">
              <div className="container" data-aos="fade-up">
                <div className="section-header">
                  <h2> Kính An Toàn Tân Nhật Minh</h2>
                  <p>Các sản phẩm của chúng tôi tuân thủ nghiêm ngặt các tiêu chuẩn quản lý chất lượng ISO 9001: 2015 trong từng công đoạn sản xuất cùng các tiêu chuẩn JIS, ANSI, EN.</p>
                </div>
                <div className="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry" data-portfolio-sort="original-order">
                  <ul className="portfolio-flters" data-aos="fade-up" data-aos-delay={100}>
                    <li data-filter="*" className="filter-active">Tất Cả</li>
                    <li data-filter=".filter-remodeling">Chứng Chỉ ISO 9001-2015 </li> 
                    <li data-filter=".filter-construction">Chứng Nhận Hợp Chuẩn</li>
                    <li data-filter=".filter-repairs">Chứng Nhận Hợp Quy</li>
                    {/* <li data-filter=".filter-design">Thiết Kế</li> */}
                  </ul>{/* End Projects Filters */}
                  <div className="row gy-4 portfolio-container" data-aos="fade-up" data-aos-delay={200}>
                    <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                      <div className="portfolio-content h-100">
                        <img src="assets/img/projects/certificate/certificate1.jpg" className="img-fluid" alt="" />
                        <div className="portfolio-info">
                          <h4>Chứng Nhận Liên Quan</h4>
                          {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                          <a href="assets/img/projects/certificate/certificate1.jpg" title="Remodeling 1" data-gallery="portfolio-gallery-remodeling" className="glightbox preview-link"><i className="bi bi-zoom-in" /></a>
                          <a href="project-details.html" title="More Details" className="details-link"><i className="bi bi-link-45deg" /></a>
                        </div>
                      </div>
                    </div>{/* End Projects Item */}

                    <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                      <div className="portfolio-content h-100">
                        <img src="assets/img/projects/certificate/certificate2.jpg" className="img-fluid" alt="" />
                        <div className="portfolio-info">
                          <h4>Chứng Nhận Liên Quan</h4>
                          {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                          <a href="assets/img/projects/certificate/certificate2.jpg" title="Remodeling 1" data-gallery="portfolio-gallery-remodeling" className="glightbox preview-link"><i className="bi bi-zoom-in" /></a>
                          <a href="project-details.html" title="More Details" className="details-link"><i className="bi bi-link-45deg" /></a>
                        </div>
                      </div>

                    </div>{/* End Projects Item */}

                    <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                      <div className="portfolio-content h-100">
                        <img src="assets/img/projects/certificate/certificate3.jpg" className="img-fluid" alt="" />
                        <div className="portfolio-info">
                          <h4>Chứng Nhận Liên Quan</h4>
                          {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                          <a href="assets/img/projects/certificate/certificate3.jpg" title="Remodeling 1" data-gallery="portfolio-gallery-remodeling" className="glightbox preview-link"><i className="bi bi-zoom-in" /></a>
                          <a href="project-details.html" title="More Details" className="details-link"><i className="bi bi-link-45deg" /></a>
                        </div>
                      </div>
                      
                    </div>{/* End Projects Item */}


                    <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                      <div className="portfolio-content h-100">
                        <img src="assets/img/projects/certificate/certificate4.jpg" className="img-fluid" alt="" />
                        <div className="portfolio-info">
                          <h4>Chứng Nhận Liên Quan</h4>
                          {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                          <a href="assets/img/projects/certificate/certificate4.jpg" title="Remodeling 1" data-gallery="portfolio-gallery-remodeling" className="glightbox preview-link"><i className="bi bi-zoom-in" /></a>
                          <a href="project-details.html" title="More Details" className="details-link"><i className="bi bi-link-45deg" /></a>
                        </div>
                      </div>
                      
                    </div>{/* End Projects Item */}

                    <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                      <div className="portfolio-content h-100">
                        <img src="assets/img/projects/certificate/certificate5.jpg" className="img-fluid" alt="" />
                        <div className="portfolio-info">
                          <h4>Chứng Nhận Liên Quan</h4>
                          {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                          <a href="assets/img/projects/certificate/certificate5.jpg" title="Remodeling 1" data-gallery="portfolio-gallery-remodeling" className="glightbox preview-link"><i className="bi bi-zoom-in" /></a>
                          <a href="project-details.html" title="More Details" className="details-link"><i className="bi bi-link-45deg" /></a>
                        </div>
                      </div>
                      
                    </div>{/* End Projects Item */}
                    <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                      <div className="portfolio-content h-100">
                        <img src="assets/img/projects/certificate/certificate6.jpg" className="img-fluid" alt="" />
                        <div className="portfolio-info">
                          <h4>Chứng Nhận Liên Quan</h4>
                          {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                          <a href="assets/img/projects/certificate/certificate6.jpg" title="Remodeling 1" data-gallery="portfolio-gallery-remodeling" className="glightbox preview-link"><i className="bi bi-zoom-in" /></a>
                          <a href="project-details.html" title="More Details" className="details-link"><i className="bi bi-link-45deg" /></a>
                        </div>
                      </div>
                      
                    </div>{/* End Projects Item */}

                    <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                      <div className="portfolio-content h-100">
                        <img src="assets/img/projects/certificate/certificate7.jpg" className="img-fluid" alt="" />
                        <div className="portfolio-info">
                          <h4>Chứng Nhận Liên Quan</h4>
                          {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                          <a href="assets/img/projects/certificate/certificate7.jpg" title="Remodeling 1" data-gallery="portfolio-gallery-remodeling" className="glightbox preview-link"><i className="bi bi-zoom-in" /></a>
                          <a href="project-details.html" title="More Details" className="details-link"><i className="bi bi-link-45deg" /></a>
                        </div>
                      </div>
                      
                    </div>{/* End Projects Item */}

                    <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                      <div className="portfolio-content h-100">
                        <img src="assets/img/projects/certificate/certificate8.jpg" className="img-fluid" alt="" />
                        <div className="portfolio-info">
                          <h4>Chứng Nhận Liên Quan</h4>
                          {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                          <a href="assets/img/projects/certificate/certificate8.jpg" title="Remodeling 1" data-gallery="portfolio-gallery-remodeling" className="glightbox preview-link"><i className="bi bi-zoom-in" /></a>
                          <a href="project-details.html" title="More Details" className="details-link"><i className="bi bi-link-45deg" /></a>
                        </div>
                      </div>
                      
                    </div>{/* End Projects Item */}

                    <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                      <div className="portfolio-content h-100">
                        <img src="assets/img/projects/certificate/certificate9.jpg" className="img-fluid" alt="" />
                        <div className="portfolio-info">
                          <h4>Chứng Nhận Liên Quan</h4>
                          {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                          <a href="assets/img/projects/certificate/certificate9.jpg" title="Remodeling 1" data-gallery="portfolio-gallery-remodeling" className="glightbox preview-link"><i className="bi bi-zoom-in" /></a>
                          <a href="project-details.html" title="More Details" className="details-link"><i className="bi bi-link-45deg" /></a>
                        </div>
                      </div>
                      
                    </div>{/* End Projects Item */}

                    <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                      <div className="portfolio-content h-100">
                        <img src="assets/img/projects/certificate/certificate10.jpg" className="img-fluid" alt="" />
                        <div className="portfolio-info">
                          <h4>Chứng Nhận Liên Quan</h4>
                          {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                          <a href="assets/img/projects/certificate/certificate10.jpg" title="Remodeling 1" data-gallery="portfolio-gallery-remodeling" className="glightbox preview-link"><i className="bi bi-zoom-in" /></a>
                          <a href="project-details.html" title="More Details" className="details-link"><i className="bi bi-link-45deg" /></a>
                        </div>
                      </div>
                      
                    </div>{/* End Projects Item */}
                    <div className="col-lg-4 col-md-6 portfolio-item filter-remodeling">
                      <div className="portfolio-content h-100">
                        <img src="assets/img/projects/certificate/certificate11.jpg" className="img-fluid" alt="" />
                        <div className="portfolio-info">
                          <h4>Chứng Nhận Liên Quan</h4>
                          {/* <p>Lorem ipsum, dolor sit amet consectetur</p> */}
                          <a href="assets/img/projects/certificate/certificate11.jpg" title="Remodeling 1" data-gallery="portfolio-gallery-remodeling" className="glightbox preview-link"><i className="bi bi-zoom-in" /></a>
                          <a href="project-details.html" title="More Details" className="details-link"><i className="bi bi-link-45deg" /></a>
                        </div>
                      </div>
                      
                    </div>{/* End Projects Item */}
                  </div>{/* End Projects Container */}
                </div>
              </div>
            </section>
    );
}

export default Ceritficates;

import React from 'react';

const HeroSection = () => {
    return (
        <section id="hero" className="hero">
            <div className="info d-flex align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 text-center">
                            <h2 data-aos="fade-down">Tân Nhật Minh Glass <span>Kính Chào Quý Khách</span></h2>
                            <p data-aos="fade-up">Đem sản phẩm kính cường lực với chất lượng tốt, khả năng chống chịu lực cơ học lớn, tính bền cao, khả năng cách âm, cách nhiệt tốt nhất đến tay quý Khách Hàng. 
</p>
                            <a data-aos="fade-up" data-aos-delay={200} href="#get-started" className="btn-get-started">Xem thêm</a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="hero-carousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval={5000}>
                <div className="carousel-item active" style={{ backgroundImage: 'url(assets/img/hero-carousel/hero-carousel-1.jpg)' }} />
                <div className="carousel-item" style={{ backgroundImage: 'url(assets/img/hero-carousel/hero-carousel-2.jpg)' }} />
                <div className="carousel-item" style={{ backgroundImage: 'url(assets/img/hero-carousel/hero-carousel-3.jpg)' }} />
                <div className="carousel-item" style={{ backgroundImage: 'url(assets/img/hero-carousel/hero-carousel-4.jpg)' }} />
                <div className="carousel-item" style={{ backgroundImage: 'url(assets/img/hero-carousel/hero-carousel-5.jpg)' }} />
                <a className="carousel-control-prev" href="#hero-carousel" role="button" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true" />
                </a>
                <a className="carousel-control-next" href="#hero-carousel" role="button" data-bs-slide="next">
                    <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true" />
                </a>
            </div>
        </section>
    );
}

export default HeroSection;

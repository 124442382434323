import React from 'react';
import imgLogo from '../../assets/img/logo/logo.jpg'
import imgLogo1 from '../../assets/img/logo/hunglong.jpg'

const HeaderComponet = () => {
    return (
        <header id="header" className="header d-flex align-items-center">
            <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
                {/* <a href="index.html" className="logo d-flex align-items-center"> */}
                <div href="index.html" className="logo d-block align-items-center">
                    {/* Uncomment the line below if you also wish to use an image logo */}
                    {/* <img src="assets/img/logo.png" alt=""> */}
                    <div style={{marginBottom:"5px"}}><img src={imgLogo} alt="" /></div>
                           <img src={imgLogo1} alt="" />       
                </div>
                <i className="mobile-nav-toggle mobile-nav-show bi bi-list" />
                <i className="mobile-nav-toggle mobile-nav-hide bi d-none bi-x" />
                <nav id="navbar" className="navbar">
                    <ul>
                        <li><a href="index.html" className="active">Trang Chủ</a></li>
                        <li><a href="about.html">Giới Thiệu</a></li>
                        <li><a href="services.html">Dịch Vụ</a></li>
                        <li><a href="projects.html">Sản Phẩm</a></li>
                        <li><a href="blog.html">Blog</a></li>
                        <li className="dropdown"><a href="#"><span>Sản Phẩm</span> <i className="bi bi-chevron-down dropdown-indicator" /></a>
                            <ul>
                                <li><a href="#">Kính Cường Lực</a></li>
                                <li><a href="#">Kính Màu</a></li>
                                <li><a href="#">Kính Phun Cát</a></li>
                                <li><a href="#">Kính Dán</a></li>
                            </ul>
                        </li>
                        <li><a href="contact.html">Liên Hệ </a></li>
                    </ul>
                </nav>{/* .navbar */}
            </div>
        </header>
    );
}

export default HeaderComponet;

import React from 'react';

const ManufacturingpProcess = () => {
    return (
        <section id="services" className="services section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Quy Trình Sản Xuất</h2>
            <p>KÍNH AN TOÀN TÂN NHẬT MINH CÓ GÌ? TẠI SAO LẠI CHỌN KÍNH AN TOÀN TÂN NHẬT MINH? 
</p>
          </div>
          <div className="row gy-4">
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={100}>
              <div className="service-item  position-relative">
                <div className="icon">
                  <i className="fa-solid fa-mountain-city" />
                </div>
                <h3>Tiếp Nhận Đơn Hàng</h3>
                <p>Thông tin liên hệ: <span style={{fontWeight:"bold"}}>0921881333 - 0966092333 - 0984627696</span>
</p>
                <a href="service-details.html" className="readmore stretched-link">Xem thêm <i className="bi bi-arrow-right" /></a>
              </div>
            </div>{/* End Service Item */}
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={200}>
              <div className="service-item position-relative">
                <div className="icon">
                  <i className="fa-solid fa-arrow-up-from-ground-water" />
                </div>
                <h3>Cắt Kính</h3>
                <p></p>
                <a href="service-details.html" className="readmore stretched-link">Xem thêm <i className="bi bi-arrow-right" /></a>
              </div>
            </div>{/* End Service Item */}
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={300}>
              <div className="service-item position-relative">
                <div className="icon">
                  <i className="fa-solid fa-compass-drafting" />
                </div>
                <h3>Mài Kính</h3>
                <p></p>
                <a href="service-details.html" className="readmore stretched-link">Xem thêm <i className="bi bi-arrow-right" /></a>
              </div>
            </div>{/* End Service Item */}
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={400}>
              <div className="service-item position-relative">
                <div className="icon">
                  <i className="fa-solid fa-trowel-bricks" />
                </div>
                <h3>Khoan , Khoét Và Rửa Kinh</h3>
                <p></p>
                <a href="service-details.html" className="readmore stretched-link">Xem thêm <i className="bi bi-arrow-right" /></a>
              </div>
            </div>{/* End Service Item */}
          
            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={500}>
              <div className="service-item position-relative">
                <div className="icon">
                  <i className="fa-solid fa-arrow-up-from-ground-water" />
                </div>
                <h3>Công Đoạn Tôi Kinh</h3>
                <p></p>
                <a href="service-details.html" className="readmore stretched-link">Xem thêm <i className="bi bi-arrow-right" /></a>
              </div>
            </div>{/* End Service Item */}

            
          </div>
        </div>
      </section>
    );
}

export default ManufacturingpProcess;

import React from 'react';
import StartedSection from '../main/StartedSection';
import CompanyIntroduce from './CompanyIntroduce';
import ManufacturingpProcess from './ManufacturingpProcess';
import PrestigeCreateBrand from './PrestigeCreateBrand';
import ListProducts from './ListProducts';
import Ceritficates from './Ceritficates';
import Testimonials from './Testimonials';
import RecentBlogPostsSection from './RecentBlogPostsSection';


const Main = () => {
    return (
        <main id="main">
       
            <CompanyIntroduce/>
            <ManufacturingpProcess/>
            <PrestigeCreateBrand/>
            <ListProducts/>
            <Ceritficates/>
            <RecentBlogPostsSection/>
            {/* <Testimonials/> */}
            {/* <StartedSection/> */}
        </main>
    );
}

export default Main;

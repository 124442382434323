import React from 'react';

const CompanyIntroduce = () => {
    return (
        <section id="constructions" className="constructions">
            <div className="container" data-aos="fade-up">
                <div className="section-header">
                    <h2>Về chúng tôi</h2>
                    <p>Hiện nay, Công ty TNHH Thương mại và Sản xuất Đức Thắng là đơn vị chuyên sản xuất kính cường lực, kính dán hàng đầu trong khu vực Bắc Miền Trung. Với các sản phẩm đa dạng, chúng tôi cam kết đem lại sự trải nghiệm tốt nhất dành cho người tiêu dùng.
                    </p>
                </div>
                <div className="row gy-4">
                
                    <div className="col-lg-6" data-aos="fade-up" data-aos-delay={100}>
                        <div className="card-item">
                            <div className="row">
                                <div className="col-xl-5">
                                    <div className="card-bg" style={{ backgroundImage: 'url(assets/img/constructions-2.jpg)' }} />
                                </div>
                                <div className="col-xl-7 d-flex align-items-center">
                                    <div className="card-body">
                                        <h4 className="card-title">KINH NGHIỆM
                                        </h4>
                                        <p>Gần 20 năm trong thị trường kính đầy biến động, kính an toàn Tân Nhật Minh ngày càng khẳng định mình, đưa thương hiệu kính an toàn DMGlass có độ nhận diện cao đối với khách hàng. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{/* End Card Item */}
                    <div className="col-lg-6" data-aos="fade-up" data-aos-delay={200}>
                        <div className="card-item">
                            <div className="row">
                                <div className="col-xl-5">
                                    <div className="card-bg" style={{ backgroundImage: 'url(assets/img/constructions-3.jpg)' }} />
                                </div>
                                <div className="col-xl-7 d-flex align-items-center">
                                    <div className="card-body">
                                        <h4 className="card-title">CÔNG NGHỆ
                                        </h4>
                                        <p>Dây chuyền công nghệ hiện đại của Italy cùng một số nước trên thê giới, với nguồn nguyên liệu của Công ty liên doanh Kính nổi tiếng Việt Nam CFG, ngoài ra phôi còn nhập khẩu từ Trung Quốc, Thái Lan, Indonesia…
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{/* End Card Item */}
                
                    <div className="col-lg-6" data-aos="fade-up" data-aos-delay={300}>
                        <div className="card-item">
                            <div className="row">
                                <div className="col-xl-5">
                                    <div className="card-bg" style={{ backgroundImage: 'url(assets/img/constructions-4.jpg)' }} />
                                </div>
                                <div className="col-xl-7 d-flex align-items-center">
                                    <div className="card-body">
                                        <h4 className="card-title">NHÂN SỰ
                                        </h4>
                                        <p>Lấy sự hài lòng của khách hàng làm kim chỉ nam trong vận hành bộ máy quản lý cũng như đội ngũ sản xuất, chúng tôi cam kết giải đáp mọi thắc mắc, phản hồi đối với sản phẩm, gửi tới khách hàng những sản phẩm tốt nhất.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{/* End Card Item */}
                    <div className="col-lg-6" data-aos="fade-up" data-aos-delay={400}>
                        <div className="card-item">
                            <div className="row">
                                <div className="col-xl-5">
                                    <div className="card-bg" style={{ backgroundImage: 'url(assets/img/constructions-5.jpg)' }} />
                                </div>
                                <div className="col-xl-7 d-flex align-items-center">
                                    <div className="card-body">
                                        <h4 className="card-title">VẬN CHUYỂN
                                        </h4>
                                        <p>Ngoài dây chuyền công nghệ và hệ thống quản lý, vận tải cũng được đầu tư mạnh để phục vụ khách hàng và công trình trên khắp cả nước. Đội ngũ giao hàng được đào tạo chuyên nghiệp, giao hàng đúng tiến độ. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{/* End Card Item */}
                </div>
            </div>
        </section>
    );
}

export default CompanyIntroduce;

import React from 'react';

const ListProducts = () => {
    return (
        <section id="features" className="features section-bg">
            <div className="container" data-aos="fade-up">
                <ul className="nav nav-tabs row  g-2 d-flex">
                    <li className="nav-item col-4">
                        <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-1">
                            <h4>Kính Cường Lực</h4>
                        </a>
                    </li>{/* End tab nav item */}
                    <li className="nav-item col-4">
                        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2">
                            <h4>Kính Low-E</h4>
                        </a>{/* End tab nav item */}
                    </li><li className="nav-item col-4">
                        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3">
                            <h4>Kính Dán An Toàn</h4>
                        </a>
                    </li>{/* End tab nav item */}
                    <li className="nav-item col-4">
                        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-4">
                            <h4>Kính Hộp</h4>
                        </a>
                    </li>{/* End tab nav item */}
                    <li className="nav-item col-4">
                        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-5">
                            <h4>Kính Phun Cát</h4>
                        </a>
                    </li>{/* End tab nav item */}
                    <li className="nav-item col-4">
                        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-6">
                            <h4>Kính Màu Trang Trí-Kính Ốp Bếp</h4>
                        </a>
                    </li>{/* End tab nav item */}
                </ul>
                <div className="tab-content">
                    <div className="tab-pane active show" id="tab-1">
                        <div className="row">
                            <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay={100}>
                                <h3>Kính cường lực</h3>
                                <p className="fst-italic">

                                    Kính cường lực là một dạng của kính thông thường được gia nhiệt đến ngưỡng biến dạng   650  <sup>0</sup>C sau đó được làm lạnh một cách nhanh chóng bằng khí nén để đạt đến mục đích cuối cùng là làm tăng ứng suất bề mặt của tấm kính (tăng độ cứng), tạo sức căng bề mặt, tăng khả năng chống chịu lực, chống chịu lực va đập mạnh, chịu được trọng tải lớn và chống bể vỡ do ứng suất nhiệt.
                                </p>
                                <ul>
                                    <li><i className="bi bi-check2-all" /> Khả năng chịu lực cao: về mặt cơ học, kính cường lực có tính chịu lực tác động lên bề mặt rất cao, gấp 4 đến 5 lần so với kính nổi thông thường cùng loại và cùng độ dày nhờ các ứng suất nén trên bề mặt tạo ra khi tôi kính, giúp cho kính chịu được rung chấn, sức gió lớn và va đập mạnh.
                                    </li>
                                    <li><i className="bi bi-check2-all" /> Khả năng chịu sốc nhiệt: kính cường lực có tính chịu sốc nhiệt( sự thay đổi nhiệt độ đột ngột) rất cao, có thể chịu được sự thay đổi nhiệt độ chênh lệch lên đến 200<sup>0</sup>C mà không bị vỡ. Trong khi kính nổi thông thường chênh lệch nhiệt độ khi có sự thay đổi đột ngột không quá 50<sup>0</sup>C.
                                    </li>
                                    <li><i className="bi bi-check2-all" />  Độ an toàn cao: kính cường lực rất khó vỡ, nhưng khi vỡ thì vỡ dưới dạng những hạt nhỏ rời, cạnh không bén, vô hại và vì vậy sẽ không gây tổn thương. Trong khi kính thường khi vỡ sẽ tạo thành những mảng lớn, nhọn, bén như dao, có tính sát thương rất lớn. Ngoài ra kính Kính cường lực còn tạo ra khả năng thoát hiểm cho con người trong các toà nhà khi xảy ra hoả hoạn. Vì vậy rõ ràng là quá trình tôi cường lực là thực sự cần thiết để đảm bảo độ an toàn cũng như đặc tính cơ học của kính kiến trúc và kính trang trí nội thất.
                                    </li>
                                    <li><i className="bi bi-check2-all" />   Dễ dành vệ sinh lau chùi. Bề mặt kính sáng bóng chính vì vậy việc lau chùi rất dễ dàng
                                    </li>
                                    <li><i className="bi bi-check2-all" />       Không bị cong vênh biến dạng
                                        Giúp lấy ánh sáng một cách tự nhiên
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay={200}>
                                <img src="assets/img/kinhCuongLuc/1.PNG" alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>{/* End tab content item */}
                    <div className="tab-pane" id="tab-2">
                        <div className="row">
                            <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                <h3>Kính Low-E</h3>
                                <p className="fst-italic">
                                    LOW-E (viết tắt của Low Energy), loại kính sử dụng công nghệ này trên bề mặt được phủ lên một loại hợp chất đặc biệt. Nó sẽ giúp kính có khả năng phát nhiệt chậm, giảm sự phát tán nhiệt và hấp thụ nhiệt chậm…
                                </p>
                                <ul>
                                    <li><i className="bi bi-check2-all" /> Khả năng khống chế nhiệt lượng: kính phủ LOW-E sẽ phản xạ nhiệt trở lại nguồn của nó, từ đó không gian được làm lạnh bên trong tủ sẽ giữ được lâu hơn, sự trao đổi nhiệt với môi trường bên ngoài luôn được giữ ở mức thấp nhất.
                                    </li>
                                    <li><i className="bi bi-check2-all" /> Khả năng cách nhiệt: tỷ lệ nhiệt lượng ở môi trường bên ngoài trao đổi với không gian bên trong tủ cũng cực kỳ thấp.
                                    </li>
                                    <li><i className="bi bi-check2-all" /> Khả năng cân bằng ánh sáng: không gây chói mắt khi nhìn từ ngoài vào hay có nguồn sáng khác bên ngoài chiếu vào như kính phản quang nên hiệu quả thẩm mỹ cao, trung bày thực phẩm rất tốt.
                                    </li>
                                    <li><i className="bi bi-check2-all" />  Tiết kiệm điện năng: khả năng giữ nhiệt và cách nhiệt cực tốt của kính mà thiết bị làm lạnh sẽ phải hoạt động ít hơn từ đó tiết kiệm đượng một lượng điện năng đáng kể.
                                    </li>
                                    <li><i className="bi bi-check2-all" />
                                        Ngoài ra cũng chính vi khả năng cách nhiệt và khống chế nhiệt lượng mà dòng kính LOW-E không bị đọng sương hay bám hơi nước trên mặt kính khi tủ hoạt động.</li>
                                </ul>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 text-center">
                                <img src="assets/img/kinhLowE/1.png" alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>{/* End tab content item */}
                    <div className="tab-pane" id="tab-3">
                        <div className="row">
                            <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                <h3>Kính Dán An Toàn</h3>
                                <p className="fst-italic">
                                    Kính an toàn 2 lớp là loại kính có cấu trúc đặc biệt với hai lớp kính. Trước khi dán kính, chúng được rửa vệ sinh sạch sẽ bằng nước sạch khử ion rồi ép vào với nhau bởi màng phim PVB (Polyvinyl  butyral : một loại chất dẻo chuyên dụng trong ngành). Khi dán phải cần nhiệt độ cao 1400C để tạo ra sự liên kết và độ trong suốt của tấm kính.
                                    <p style={{ marginTop: "5px" }}>Chiều dày của kính an toàn 2 lớp thường được sử dụng là : 6,38mm ; 8,38 mm; 9,76 mm; 10,38 mm; 13,38; 15,76 mm; 16,38 mm; 20,38 mm; ….hay còn goi là 6,38 ly; 8,38 ly; 9,76 ly; 10,38 ly; 13,38 ly; 15,76 ly; 16,38 ly; 20,38 ly; ….</p>
                                </p>
                                <ul>

                                    <li><i className="bi bi-check2-all" /> Hạn chế tia cực tím: Kính an toàn có loại chống được đến 99% tác hại của tia cực tím mà không ảnh hưởng tới độ sáng cần thiết cho tầm nhìn của người và sự phát triển của cây cối.
                                    </li>
                                    <li><i className="bi bi-check2-all" /> An toàn: Kính an toàn không thể bị vỡ đột ngột như các tấm kính thường, lớp PVB ở giữa sẽ làm giảm hiệu quả của mọi sự tác động ngoại lực, tấm kính vẫn nguyên vẹn ngay khi bị vỡ, những mảnh kính sẽ vẫn ở nguyên vị trí làm giảm bớt sự nguy hiểm cho người sử dụng.
                                    </li>
                                    <li><i className="bi bi-check2-all" />
                                        An ninh: Kính an toàn có khả năng chống lại sự đột nhập cao hơn bởi vì có lớp bảo vệ ngay cả khi kính bị đập vỡ. Kính an toàn không thể cắt từ một phía, vì thế thông thường những dụng cụ cắt kính và những dụng cụ thông thường là không có tác dụng. Tùy thuộc vào độ dày, loại kính an toàn có lớp PVB còn có thể chịu đựng được sức nổ của bom và đạn súng trường.
                                       
                                    </li>
                                    <li><i className="bi bi-check2-all" />
                                        Giảm tiếng ồn: Kính an toàn có khả năng cách âm tốt hơn so với kính thường. Đối với các sân bay, khách sạn, trung tâm xử lý dữ liệu, các phòng thu âm và bất cứ một tòa nhà nào gần sân bay, đường cao tốc hay đường tàu thì kính an toàn rất hữu hiệu. Các tòa nhà lắp kính an toàn giảm tiếng ồn từ bốn phía, bảo đảm cho chúng ta một môi trường làm việc hoặc nghỉ ngơi yên tĩnh hơn rất nhiều.
                                    </li>
                                    <li><i className="bi bi-check2-all" />
                                        Chịu nhiệt và điều khiển sự hấp thụ nhiệt: Kính làm giảm sự hấp thụ nhiệt, điều khiển độ chói và làm cho tòa nhà mát mẻ hơn. Kính an toàn cũng đáp ứng tiêu chuẩn của thủy tinh cách nhiệt, khi tiếp xúc với nhiệt độ cao không bị vỡ do đó sẽ giữ chân ngọn lửa, tạo điều kiện cho sự chữa cháy, sơ tán mọi người và dập tắt hỏa hoạn một cách tốt nhất.
                                    
                                    </li>
                                    <li><i className="bi bi-check2-all" />
                                        Độ bền cao hơn: Kính an toàn có độ bền vững khá cao, thậm chí người ta còn dùng kính an toàn để làm sàn nhà, bể bơi, mái che, mái vòm (uốn), các sun-room và các tòa nhà ở những nơi có thời tiết khắc nghiệt.
                                    
                                    </li>
                                    <li><i className="bi bi-check2-all" />
                                        Ưu thế về mỹ thuật và kiểu dáng: Kính có màu sắc, độ dày, kích thước và hình thể đa dạng giúp các kiến trúc sư có nhiều sự lựa chọn hơn.
                                        
                                    </li>
                                    <li><i className="bi bi-check2-all" />
                                        Sự biến dạng thấp: Nếu được nung với điều kiện nhiệt độ và áp suất cao, kính vẫn tránh được sự méo mó do đã được nung trước đó. Hình ảnh khi nhìn qua kính đẹp và trung thực hơn thông thường.
                                    </li>
                                </ul>

                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 text-center">
                                <img src="assets/img/kinhAnToan/kinhDan.jpg" alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>{/* End tab content item */}
                    <div className="tab-pane" id="tab-4">
                        <div className="row">
                            <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                <h3>Kính Hộp</h3>
                                <p className="fst-italic">
                                    Kính hộp (insulating glass) là một loại kính đặc biệt, được cấu tạo từ 2 hay nhiều lớp kính, Giữa các lớp kính được ngăn cách bởi thanh đệm nhôm bên trong có chứa hạt hút ẩm. Lớp keo bên ngoài sẽ liên kết các lớp kính và thanh nhôm định hình. Các hạt hút ẩm có tác dụng hút lớp không khí bên trong, tạo thành một lớp không khí khô và ngăn cản sự truyền nhiệt hết sức hiệu quả.
                                </p>
                                <ul>
                                    <li><i className="bi bi-check2-all" /> Đặc tính siêu cách âm: Với kết cấu dạng hộp bởi các lớp kính ngăn cách bằng lớp trung không, kính hộp có thể làm giảm tối đa tiếng ồn không mong muốn từ môi trường xung quanh. Mức độ cách âm khác nhau tùy thuộc vào yêu cầu, công ty có các kết cấu và tính chất khác nhau.

                                    </li>
                                    <li><i className="bi bi-check2-all" />  Đặc tính siêu cách nhiệt: Khoảng không giữa các lớp kính được nạp khí Argon (khí trơ) làm tăng tối đa khả năng cách nhiệt, giúp cho bên trong tòa nhà luôn mát về mùa hè và ấm áp về mùa đông, giảm đáng kể nhu cầu điều hoà không khí và sưởi ấm.

                                    </li>
                                    <li><i className="bi bi-check2-all" />   Đặc tính an toàn: Khi được kết hợp với kính dán an toàn hoặc kính tôi cường lực an toàn sẽ tạo ra các loại kính hộp cách âm cách nhiệt an toàn hoàn hảo, hội tụ toàn diện các lợi ích của kính kiến trúc.

                                    </li>
                                    <li><i className="bi bi-check2-all" />   Sự linh hoạt trong thiết kế, kiến trúc.
                                    </li>
                                    <li><i className="bi bi-check2-all" />   Ngoài ra, sử dụng kính hộp còn ngăn chặn sự ngưng tụ sương, hơi nước trên bề mặt kính, hạn chế sự tích trữ hơi ẩm, tạo mùi khó chịu, từ đó tạo môi trường bên trong thoải mái và sạch sẽ.
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 text-center">
                                <img src="assets/img/kinhHop/kinhHop.jpg" alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>{/* End tab content item */}

                    <div className="tab-pane" id="tab-5">
                        <div className="row">
                            <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                <h3>Kính Phun Cát</h3>
                                <p className="fst-italic">
                                    Kính phun cát là loại kính được phủ mờ bởi được phun lên 1 lớp cát mỏng lên bề mặt kính. Việc ứng dụng phủ mờ lên bề mặt kính này sẽ không gây thay đổi đặc tính của kính mà chỉ là thay đổi bề mặt kính từ trơn phẳng, trong suốt thành mặt sần, trắng mờ. Và cũng nhờ vào ứng dụng phun cát mà kính cường lực mờ này có thể được tùy biến tạo hình, mẫu mã hoa văn trên kính rất đẹp và độc đáo.
                                </p>
                                <ul>
                                    <li><i className="bi bi-check2-all" /> Kính phun cát mờ vẫn đảm bảo an toàn cho người sử dụng bởi chúng sở hữu đầy đủ những ưu điểm của kính cường lực như: Độ an toàn, độ bền bỉ, chắc chắn, chịu nhiệt, thẩm mỹ cao.</li>
                                    <li><i className="bi bi-check2-all" /> Kính được phun lên 1 lớp cát mờ dựa trên quy trình sản xuất kính mờ cường lực hiện đại. Nên quý khách hàng không phải lo lắng khi kính mờ có đẹp không, có phai không, có mịn màng bám lên bề mặt không,…</li>
                                    <li><i className="bi bi-check2-all" /> Dễ dàng ứng dụng vào đời sống, trang trí. Đặc biệt với ưu điểm: Dễ dàng tạo không gian riêng, tạo sự kín đáo mà vẫn đảm bảo tính thẩm mỹ cho không gian. Đã khiến kính cường lực mờ có chỗ đứng mạnh mẽ trong ngành vật liệu kính như ngày hôm nay.</li>
                                </ul>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 text-center">
                                <img src="assets/img/kinhPhunCat/2.jpg" alt="" className="img-fluid" />
                            </div>
                        </div>


                    </div>{/* End tab content item */}

                    <div className="tab-pane" id="tab-6">
                        <div className="row">
                            <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                <h3>Kính Màu Trang Trí - Kính Ốp Bếp </h3>
                                <p className="fst-italic">
                                    Kính ốp tường bếp hay còn gọi là kính sơn màu là vật liệu kính xây dựng được phun lên bề mặt một lớp sơn vào mặt sau của tấm kính nhằm để tạo nên những màu sắc  đa dạng khác nhau tùy thuộc vào mục đích của người sử dụng.
                                    Kính sơn màu ngày nay chủ yếu được sử dụng tốt nhất trong trang trí nội thất và ngoại thất dân dụng và công nghiệp xây dựng bởi nhiều ưu điểm của chúng  .
                                </p>
                                <ul>
                                    <li><i className="bi bi-check2-all" /> Kính có độ phẳng, sáng bóng, màu sắc phong phú, không thấm nước. </li>
                                    <li><i className="bi bi-check2-all" /> Khó bám bụi, dễ lau chùi vệ sinh, sạch sẽ.</li>
                                    <li><i className="bi bi-check2-all" /> Có cảm giác rộng rãi, bền đẹp với thời gian, giá thành cạnh tranh với các vật liệu thông thường khác.</li>
                                </ul>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 text-center">
                                <img src="assets/img/kinhMau/5.jpg" alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>{/* End tab content item */}
                </div>
            </div>
        </section>
    );
}

export default ListProducts;

import React from 'react';
import imgLogo from '../../assets/img/logo/logo.jpg'

const Footer = () => {
    return (
        <div>
            <footer id="footer" className="footer">
                <div className="footer-content position-relative">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="footer-info">
                                    <h3>
                                        <img src={imgLogo} alt="" />
                                    </h3>
                                    <p>
                                        Trụ sở công ty: Cụm công nghiệp phía Bắc, <br />
                                        Thị Trấn Quán Lào , Huyện Yên Định, tỉnh Thanh Hóa<br /><br />
                                        <strong>Tổng đài chăm sóc :</strong>  0921881333<br />
                                        <strong>Email:</strong> tannhatminhglass@gmail.com<br />
                                    </p>
                                    <div className="social-links d-flex mt-3">
                                        <a href="#" className="d-flex align-items-center justify-content-center"><i className="bi bi-twitter" /></a>
                                        <a href="#" className="d-flex align-items-center justify-content-center"><i className="bi bi-facebook" /></a>
                                        <a href="#" className="d-flex align-items-center justify-content-center"><i className="bi bi-instagram" /></a>
                                        <a href="#" className="d-flex align-items-center justify-content-center"><i className="bi bi-linkedin" /></a>
                                    </div>
                                </div>
                            </div>{/* End footer info column*/}
                            <div className="col-lg-2 col-md-3 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><a href="#">Trang Chủ</a></li>
                                    <li><a href="#">Về Chúng Tôi</a></li>
                                    <li><a href="#">Sản Phẩm</a></li>
                                    {/* <li><a href="#">Chính Sách Công Ty</a></li> */}
                                    {/* <li><a href="#">Privacy policy</a></li> */}
                                </ul>
                            </div>{/* End footer links column*/}
                            <div className="col-lg-2 col-md-3 footer-links">
                                <h4>Chính Sách Công Ty </h4>
                                <ul>
                                    <li><a href="#">Liên hệ tư vấn</a></li>
                                    <li><a href="#">Dịch vụ</a></li>
                                    <li><a href="#">Marketing</a></li>
                                    {/* <li><a href="#">Graphic Design</a></li> */}
                                </ul>
                            </div>{/* End footer links column*/}
                            <div className="col-lg-4 col-md-3 footer-links">
                                <h4>Địa chỉ</h4>
                                <div><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11075.343498117381!2d105.65937641623245!3d19.97863168626682!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3136f3dfb4cb1f03%3A0xa3fee948e64e6ea!2sTan%20Nhat%20Minh%20Glass!5e0!3m2!1svi!2s!4v1679997474930!5m2!1svi!2s" style={{ width: "600", height: "450", style: "border:0;", allowfullscreen: "", loading: "lazy", referrerpolicy: "no-referrer-when-downgrade" }}
                                ></iframe></div>

                            </div>{/* End footer links column*/}

                        </div>
                    </div>
                </div>
                <div className="footer-legal text-center position-relative">
                    <div className="container">
                        <div className="copyright">
                            © Copyright <strong><span>   Tân Nhật Minh </span></strong>. All Rights Reserved
                        </div>
                        <div className="credits">

                        </div>
                    </div>
                </div>
            </footer>
            <a href="#" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short" /></a>
            {/* <div id="preloader" /> */}
        </div>
    );
}

export default Footer;

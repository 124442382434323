import React from 'react';
import HeaderComponet from './HeaderComponet';
import HeroSection from './HeroSection';

const Header = () => {
    return (
        <div>
            <HeaderComponet/>
            <HeroSection/>
        </div>
    );
}

export default Header;

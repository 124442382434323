import React from 'react';

const PrestigeCreateBrand = () => {
  return (
    <section id="alt-services" className="alt-services">
      <div className="container" data-aos="fade-up">
        <div className="row justify-content-around gy-4">
          <div className="col-lg-6 img-bg" style={{ backgroundImage: 'url(assets/img/alt-services.jpg)' }} data-aos="zoom-in" data-aos-delay={100} />
          <div className="col-lg-5 d-flex flex-column justify-content-center">
            <h3>UY TÍN TẠO NÊN THƯƠNG HIỆU</h3>
            <p>Mặt hàng kính cường lực là mặt hàng tuy mới lạ nhưng càng ngày lại có độ phổ biến cao với sự hữu dụng của nó. Kính cường lực Tân Nhật Minh mang đến một hơi thở mới, đặc tính trang trí tân tiến và những hữu dụng mà nó đem lại. Nó như làm thay đổi cách suy nghĩ và xu hướng sử dụng vật liệu xây dựng trong thiết kế và trang trí nội thất ngày nay.</p>
            <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay={100}>
              <i className="bi bi-easel flex-shrink-0" />
              <div>
                <h4><a href className="stretched-link">QUY MÔ SẢN XUẤT
                </a></h4>
                <p>Kính cường lực: hơn 500.000m/ năm  </p>
                <p>Kính dán : hơn 1.000.000 m/ năm
                </p>
              </div>
            </div>{/* End Icon Box */}
            <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay={200}>
              <i className="bi bi-patch-check flex-shrink-0" />
              <div>
                <h4><a href className="stretched-link">Công Nghệ Hiện Đại</a></h4>
                <p>Dây chuyền công nghệ hiện đại của Italy cùng một số nước trên thê giới, với nguồn nguyên liệu của Công ty liên doanh Kính nổi tiếng Việt Nam CFG, ngoài ra phôi còn nhập khẩu từ Trung Quốc, Thái Lan, Indonesia…
                </p>
              </div>
            </div>{/* End Icon Box */}
            <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay={300}>
              <i className="bi bi-brightness-high flex-shrink-0" />
              <div>
                <h4><a href className="stretched-link">Tận Tâm </a></h4>
                <p>Luôn đặt chữ Tin làm đầu</p>
              </div>
            </div>{/* End Icon Box */}
            {/* <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay={400}>
              <i className="bi bi-brightness-high flex-shrink-0" />
              <div>
                <h4><a href className="stretched-link">Tride clov</a></h4>
                <p>Est voluptatem labore deleniti quis a delectus et. Saepe dolorem libero sit non aspernatur odit amet. Et eligendi</p>
              </div>
            </div> */}
            {/* End Icon Box */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrestigeCreateBrand;
